export function isObject (value) {
  return value && typeof value === 'object' && value.constructor === Object;
}
export function isArray (value) {
  return value && typeof value === 'object' && value.constructor === Array;
}
export function formatNumber(amount, decimalCount = 2, decimal = ",", thousands = ".") {
  amount = parseFloat(amount);
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
    
    const negativeSign = amount < 0 ? "-" : "";
    
    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;
    
    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    console.log(e)
  }
}
export function mediaDevicesPolyfills() {
  if (!('mediaDevices' in navigator)) {
      navigator.mediaDevices = {};
  }
  if (!('getUserMedia' in navigator.mediaDevices)) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
          let getUsrMd = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
          if (!getUsrMd)
              return Promise.reject(new Error('getUserMedia is not implemented!'));
          else
              return new Promise(function (resolve, reject) {
                  getUsrMd.call(navigator, constraints, resolve, reject);
              });
      };
  }
}
export function createFormDataFromState(dataObj, exception){
  let postFormData = new URLSearchParams(), tempVal, i, j;
  if(!isArray(exception)) exception = [];
  Object.keys(dataObj).forEach(function(key,index) {
    if(exception.indexOf(key) < 0) {
      tempVal = dataObj[key];
      if(isArray(tempVal))
        for (i = 0; i < tempVal.length; i++) {
          if(isArray(tempVal[i]))
            for (j = 0; j < tempVal[i].length; j++) postFormData.append(key + "[" + i + "][" + j + "]", tempVal[i][j]);
          else
            postFormData.append(key + "[" + i + "]", tempVal[i]);
        }
      else
        postFormData.append(key, tempVal);
    }
  });
  return postFormData;
}
export function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  let ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], {type: mimeString});
}
export function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

  let rawData = window.atob(base64);
  let outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}