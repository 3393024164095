import React from 'react';
import { Link } from "react-router-dom";

import { askForNotificationPermission } from './helper/notification_handler.js';

function Nav(props) {
  let enNotifBtn = null;
  if (props.showEnableNotifBtn) {
    enNotifBtn = (
      <div className="drawer-option">
        <button className="mdl-button mdl-js-button mdl-button--raised mdl-button--colored mdl-color--accent" onClick={askForNotificationPermission}>
          Enable Notifications
        </button>
      </div>
    );
  }
  return (
    <>
      <header className="mdl-layout__header">
        <div className="mdl-layout__header-row">
          {/* Title */}
          <span className="mdl-layout-title">WeTeGram</span>
          {/* Add spacer, to align navigation to the right */}
          <div className="mdl-layout-spacer"></div>
          {/* Navigation. We hide it in small screens. */}
          <nav className="mdl-navigation mdl-layout--large-screen-only">
            <Link className="mdl-navigation__link" to="/">Feed</Link>
            <Link className="mdl-navigation__link" to="/360-demo">360 Demo</Link>
            <Link className="mdl-navigation__link" to="/check-out">Check Out</Link>
            {enNotifBtn}
          </nav>
        </div>
      </header>
      <div className="mdl-layout__drawer">
        <span className="mdl-layout-title">WeTeGram</span>
        <nav className="mdl-navigation">
          <Link className="mdl-navigation__link" to="/">Feed</Link>
          <Link className="mdl-navigation__link" to="/360-demo">360 Demo</Link>
          <Link className="mdl-navigation__link" to="/check-out">Check Out</Link>
          {enNotifBtn}
        </nav>
      </div>
    </>
  );
}
export default Nav;