import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './css/app.css';

import Nav from './Nav.js';
const ThreeSixtyDemo = lazy(() => import('./360-demo/ThreeSixtyDemo.js'));
const PageNotFound = lazy(() => import('./404.js'));
const CheckOut = lazy(() => import('./checkOut/CheckOut.js'));
const Post = lazy(() => import('./feed/Post.js'));
const Feed = lazy(() => import('./feed/Feed.js'));
//import Footer from './Footer';

function App(props) {
  return (
    <Router>
      <div className="mdl-layout mdl-js-layout mdl-layout--fixed-header">
        <Nav showEnableNotifBtn={props.showEnableNotifBtn} />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path="/360-demo" exact>
              <ThreeSixtyDemo />
            </Route>
            <Route path="/404" exact>
              <PageNotFound />
            </Route>
            <Route path="/check-out" exact>
              <CheckOut />
            </Route>
            <Route path="/post/:id(\d+)" exact render={(props) => {
              return <Post {...props} />;
            }} />
            <Route path="/" exact>
              <Feed prompEvent={props.prompEvent} promptEventShowed={props.promptEventShowed} />
            </Route>
            <Route component={PageNotFound} />
          </Switch>
        </Suspense>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

export default App;