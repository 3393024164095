import { APP_URL, BACK_END_API_URL, VAPID_PUBLIC_KEY } from './config.js';
import { urlBase64ToUint8Array } from './utilites.js';

function displayComfirmNotification() {
  let options = {
    body: 'You Have Successfully Enable Notification and Subscribe to It.',
    lang: 'en-US',
    dir: 'ltr',
    icon: APP_URL + '/src/images/icons/app-icon-96x96.png',
    badge: APP_URL + '/src/images/icons/app-icon-96x96.png',
    vibrate: [100, 50, 200],
    tag: 'confirm-notification',
    renotify: true
  };

  if ('serviceWorker' in navigator) {
    options.actions = [
      { action: 'oke', title: 'Ok' }
    ];
    navigator.serviceWorker.ready.then(function (swIsntance) {
      swIsntance.showNotification("Successfully Subscribe", options);
    });
  }
  else new Notification("Notification Granted", options);
}
function configureNotificationPushSubscription() {
  if ('serviceWorker' in navigator) {
    var swRegInsClone;
    navigator.serviceWorker.ready
      .then(function (swRegInstance) {
        swRegInsClone = swRegInstance;
        return swRegInstance.pushManager.getSubscription();
      })
      .then(function (subsInstance) {
        if (subsInstance === null) {
          // generated with 'php artisan webpush:vapid' at laravel back end
          let vapidPublicKey = urlBase64ToUint8Array(VAPID_PUBLIC_KEY);
          swRegInsClone.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: vapidPublicKey
          })
            .then(function (newSubs) {
              const newSubsJSON = newSubs.toJSON();
              console.log(newSubsJSON);
              let postFormData = new FormData();
              postFormData.append('endpoint', newSubsJSON.endpoint);
              postFormData.append('key_auth', newSubsJSON.keys.auth);
              postFormData.append('key_p256dh', newSubsJSON.keys.p256dh);

              return fetch(BACK_END_API_URL + "/api/push/create", {
                method: 'POST',
                headers: {
                  'Accept': 'application/json'
                },
                body: postFormData
              });
            })
            .then(function (res) {
              if (res.status === 201)
                displayComfirmNotification();
              else
                return Promise.reject(new Error(res.status));
            })
            .catch(function (err) {
              console.log('Error subscribing to push notification.', err);
            });
        }
        else {
          const subsJSON = subsInstance.toJSON();
          console.log(subsJSON);
          let postFormData = new FormData();
          postFormData.append('endpoint', subsJSON.endpoint);
          postFormData.append('key_auth', subsJSON.keys.auth);
          postFormData.append('key_p256dh', subsJSON.keys.p256dh);

          fetch(BACK_END_API_URL + "/api/push/update", {
            method: 'POST',
            headers: {
              'Accept': 'application/json'
            },
            body: postFormData
          })
            .then(function (res) {
              if (res.status === 201)
                displayComfirmNotification();
              else
                return Promise.reject(new Error(res.status));
            })
            .catch(function (err) {
              console.log('Error subscribing to push notification.', err);
            });
        }
      })
      .catch(function(err){
        console.log("gak masuk", err);
      });
  }
}
export function askForNotificationPermission() {
  Notification.requestPermission(function (permissionResult) {
    if (permissionResult === 'granted') {
      console.log('Notification permission granted');
      //displayComfirmNotification();
      configureNotificationPushSubscription();
    }
    else console.log('Notification permission denied.');
  });
}