import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { mediaDevicesPolyfills } from './helper/utilites.js';

let promptEvent = null;
function promptEventShowed(){
  promptEvent = "showed";
}

(function () {
  mediaDevicesPolyfills();

  window.addEventListener('beforeinstallprompt', function (ev) {
    ev.preventDefault();
    if (typeof promptEvent === undefined || promptEvent === undefined || promptEvent === null)
      promptEvent = ev;
    return false;
  });

  let showEnableNotifBtn = false;
  if ('Notification' in window && 'serviceWorker' in navigator) showEnableNotifBtn = true;

  ReactDOM.render(
    <React.StrictMode>
      <App promptEvent={promptEvent}
           promptEventShowed={promptEventShowed}
           showEnableNotifBtn={showEnableNotifBtn}
      />
    </React.StrictMode>,
    document.getElementById('root')
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.register();
})();